@font-face {
  font-family: "Helvetica Now";
  src: url("fonts/helveticanow_regular.otf") format("opentype");
  font-display: auto;
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Helvetica Now";
  src: url("fonts/helveticanow_medium.otf") format("opentype");
  font-display: auto;
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Helvetica Now";
  src: url("fonts/helveticanow_bold.otf") format("opentype");
  font-display: auto;
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Ivar Headline";
  src: url("fonts/ivar_regular.otf") format("opentype");
  font-display: auto;
  font-style: normal;
}
@tailwind base;
@tailwind components;
@tailwind utilities;
:root {
  --c-primary-red: #AB2E24;
  --c-primary-green: #A1A39B;
  --c-primary-white: #F7F5F0;
  --c-primary-black: #050505;
  --c-secondary-beige: #AB9E96;
  --c-secondary-blue: #435867;
  --c-secondary-orange: #B9753A;
  --c-secondary-gray: #B5B2B5;
  --c-extra-darkgreen: #454D3D;
  --c-extra-lightgreen: #E6E5DF;
  --c-extra-white: #FEFEFD;
  --c-extra-pink: #E9D1CB;
  --c-extra-lightbeige: #E4DFDA;
  --c-extra-darkred: #7E221B;
  --c-opacity-black: rgba(5, 5, 5, 0.5);
  --c-opacity-gray: rgba(181, 178, 181, 0.5);
  --c-opacity-red: rgba(171, 46, 36, 0.5);
  --c-primary: var(--c-primary-red);
  --c-hover: var(--c-extra-darkred);
  --c-text: var(--c-primary-black);
  --c-text-contrast: var(--c-primary-white);
  --c-link-hover: var(--c-secondary-blue);
  --c-headers: var(--c-primary-black);
  --f-family-sans: "Helvetica Now", sans-serif;
  --f-family-serif: "Ivar Headline", serif;
  --f-family-fontawesome: "Font Awesome 6 Pro";
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  background-color: var(--c-primary-white);
  font-family: var(--f-family-sans);
  color: var(--c-text);
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 1.5;
}
body.mobile-menu-open #main-content,
body.mobile-menu-open footer {
  display: none;
}
body.mobile-menu-open header.header--main-header {
  height: 100vh;
}
body.mobile-menu-open header.header--main-header .header-image {
  position: relative;
  z-index: 15;
}
body.mobile-menu-open header.header--main-header .header-image__image {
  background-image: url(/static/website/images/logo_dark.svg);
}
body.mobile-menu-open header.header--main-header .header__mobile-menu {
  color: var(--c-text);
}

.to-maincontent-button {
  position: absolute;
  z-index: 1000;
  left: -10rem;
  top: -10rem;
  opacity: 0;
}
.to-maincontent-button:focus {
  opacity: 1;
  left: 3rem;
  top: 3rem;
}

/* Typographi */
h1, h2, h3, h4, h5, h6, .subtitle,
.h1, .h2, .h3, .h4, .h5 {
  font-family: var(--f-family-serif);
  font-weight: 400;
  line-height: 1.2;
  color: var(--c-headers);
}
h1.title--sans, h2.title--sans, h3.title--sans, h4.title--sans, h5.title--sans, h6.title--sans, .subtitle.title--sans,
.h1.title--sans, .h2.title--sans, .h3.title--sans, .h4.title--sans, .h5.title--sans {
  font-family: var(--f-family-sans);
  line-height: 1.5;
  font-weight: 500;
}

h1, .h1 {
  font-size: 3.6rem;
}
@media (min-width: 768px) {
  h1, .h1 {
    font-size: 6.4rem;
  }
}
h1.title--sans, .h1.title--sans {
  font-size: 3.2rem;
}
@media (min-width: 768px) {
  h1.title--sans, .h1.title--sans {
    font-size: 4.8rem;
  }
}

h2, .h2 {
  font-size: 2.8rem;
}
@media (min-width: 768px) {
  h2, .h2 {
    font-size: 4rem;
  }
}
h2.title--sans, .h2.title--sans {
  font-size: 2.4rem;
}
@media (min-width: 768px) {
  h2.title--sans, .h2.title--sans {
    font-size: 3.2rem;
  }
}

h3, .h3 {
  font-size: 2rem;
}
@media (min-width: 768px) {
  h3, .h3 {
    font-size: 2.4rem;
  }
}
h3.title--sans, .h3.title--sans {
  font-size: 2rem;
}
@media (min-width: 768px) {
  h3.title--sans, .h3.title--sans {
    font-size: 2.4rem;
  }
}

h4, .h4 {
  font-size: 1.8rem;
}
@media (min-width: 768px) {
  h4, .h4 {
    font-size: 2rem;
  }
}
h4.title--sans, .h4.title--sans {
  font-size: 1.4rem;
  font-weight: 700;
}
@media (min-width: 768px) {
  h4.title--sans, .h4.title--sans {
    font-size: 1.6rem;
  }
}

h5, .h5 {
  font-size: 1.6rem;
}
@media (min-width: 768px) {
  h5, .h5 {
    font-size: 1.8rem;
  }
}
h5.title--sans, .h5.title--sans {
  font-size: 1.2rem;
  font-weight: 700;
}
@media (min-width: 768px) {
  h5.title--sans, .h5.title--sans {
    font-size: 1.4rem;
  }
}

h6, .h6 {
  font-size: 1.4rem;
}
@media (min-width: 768px) {
  h6, .h6 {
    font-size: 1.6rem;
  }
}
h6.title--sans, .h6.title--sans {
  font-size: 1rem;
  font-weight: 700;
}
@media (min-width: 768px) {
  h6.title--sans, .h6.title--sans {
    font-size: 1.2rem;
  }
}

.text--small {
  font-size: 1.6rem;
}

.text--big {
  font-size: 2rem;
}
@media (min-width: 768px) {
  .text--big {
    font-size: 2.4rem;
  }
}

.details {
  font-size: 1.4rem;
}

a {
  color: var(--c-link);
  text-decoration: underline;
  cursor: pointer;
}
a:is(:hover, :active) {
  color: var(--c-link-hover);
}
a:focus {
  outline: 2px solid var(--c-link-hover);
  border-radius: 0.4rem;
}

table {
  width: 100%;
  max-width: 100%;
}
table::-webkit-scrollbar {
  -webkit-appearance: none;
  height: 6px;
}
table::-webkit-scrollbar-thumb {
  background: var(--c-primary-green);
  border-radius: 5px;
  cursor: pointer;
}
table::-webkit-scrollbar-thumb:hover {
  background: var(--c-secondary-gray);
}
table tbody {
  width: 100%;
}
table td, table th {
  padding: 1rem;
  padding-left: 0;
  border-bottom: 1px solid var(--c-primary-green);
  text-align: left;
}
table tbody tr:last-child td, table tbody tr:last-child th {
  border-bottom: 0;
}

.text-body p + p {
  margin-top: 1em;
}
.text-body h2, .text-body h3, .text-body h4 {
  font-family: var(--f-family-sans);
  margin-bottom: 0.5em;
}
.text-body * + h2, .text-body * + h3, .text-body * + h4 {
  margin-top: 1.5em;
}
.text-body h2 {
  font-size: 2.4rem;
}
@media (min-width: 768px) {
  .text-body h2 {
    font-size: 3.2rem;
  }
}
.text-body h3 {
  font-size: 2rem;
}
@media (min-width: 768px) {
  .text-body h3 {
    font-size: 2.8rem;
  }
}
.text-body h4 {
  font-size: 1.8rem;
}
@media (min-width: 768px) {
  .text-body h4 {
    font-size: 2.4rem;
  }
}
.text-body hr {
  margin-top: 4rem;
  margin-bottom: 4rem;
  border: 1px solid var(--c-primary-red);
}
@media (min-width: 768px) {
  .text-body hr {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }
}
.text-body ol, .text-body ul {
  margin-block-start: 1em;
  margin-block-end: 1em;
  padding-inline-start: 4rem;
  margin-top: 1em;
  margin-bottom: 1em;
}
.text-body ol ol, .text-body ol ul, .text-body ul ol, .text-body ul ul {
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
}
.text-body ol {
  list-style-type: decimal;
}
.text-body ul {
  list-style-type: disc;
}

.max-page {
  margin-left: auto;
  margin-right: auto;
  max-width: 200rem;
  padding: 0 4rem;
  width: 100%;
}

.max-text {
  margin-left: auto;
  margin-right: auto;
  max-width: 71rem;
}

.background-white {
  background-color: var(--c-primary-white);
}

.background-green {
  background-color: var(--c-primary-green);
}

.background-blue {
  background-color: var(--c-secondary-blue);
}

.background-beige {
  background-color: var(--c-secondary-beige);
}

.background-light_green {
  background-color: var(--c-extra-lightgreen);
}

.background-red {
  background-color: var(--c-primary-red);
}

.background-extra_white {
  background-color: var(--c-extra-white);
}

.background-gray {
  background-color: var(--c-secondary-gray);
}

.background-orange {
  background-color: var(--c-secondary-orange);
}

.color-light {
  color: var(--c-text-contrast);
}
.color-light h1, .color-light h2, .color-light h3, .color-light h4, .color-light h5, .color-light h6, .color-light p, .color-light a {
  color: var(--c-text-contrast);
}
.color-light a:hover {
  color: var(--c-extra-pink);
}
.color-light a:focus {
  outline-color: var(--c-text-contrast);
}
.color-light .btn-secondary {
  border-color: var(--c-text-contrast);
  color: var(--c-text-contrast);
}

.color-dark {
  color: var(--c-text);
}
.color-dark h1, .color-dark h2, .color-dark h3, .color-dark h4, .color-dark h5, .color-dark h6, .color-dark p, .color-dark a {
  color: var(--c-text);
}
.color-dark a:hover {
  color: var(--c-secondary-blue);
}
.color-dark a:focus {
  outline-color: var(--c-text);
}
.color-dark .btn-secondary {
  border-color: var(--c-text);
  color: var(--c-text);
}

.richtext-image.left {
  float: left;
  margin: 2rem 2rem 2rem 0;
}
.richtext-image.right {
  float: right;
  margin: 2rem 0 2rem 2rem;
}
.richtext-image.full-width {
  margin: 2rem 0;
  width: 100%;
}

.btn {
  font-size: 1.8rem;
  line-height: 1.8;
  padding: 0.8rem 2.4rem;
  border-radius: 0.4rem;
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
  border-width: 0.1rem;
  border-style: solid;
  transition: 0.2s;
}
.btn:is(:hover, :focus, :active) {
  outline: none;
}
.btn i {
  margin-left: 1rem;
}

.btn-primary {
  border-color: var(--c-primary);
  background-color: var(--c-primary);
  color: var(--c-text-contrast);
}
.btn-primary:is(:hover, :focus, :active) {
  color: var(--c-text-contrast);
  border-color: var(--c-hover);
  background-color: var(--c-hover);
}

.btn-secondary {
  border-color: var(--c-text);
  background-color: transparent;
  color: var(--c-text);
}
.btn-secondary:is(:hover, :focus, :active) {
  border-color: var(--c-primary);
  background-color: var(--c-primary);
  color: var(--c-text-contrast);
}

.btn-action {
  border-color: transparent;
  background-color: var(--c-primary-white);
  border-radius: 50%;
  color: var(--c-text);
  padding: 8px 15px;
}
.btn-action i {
  margin: 0;
}
.btn-action:is(:hover, :focus, :active) {
  border-color: var(--c-primary);
  background-color: var(--c-primary);
  color: var(--c-text-contrast);
  border-radius: 50%;
}

.popover {
  position: fixed;
  inset: 0;
  width: fit-content;
  height: fit-content;
  margin: auto;
  overflow: auto;
  z-index: 1;
  max-width: 60rem;
  text-align: center;
  background-color: var(--c-extra-white);
}
.popover__content {
  padding: 2.5rem;
  margin: auto;
  max-width: 50rem;
}
.popover__footer {
  background-color: var(--c-extra-lightgreen);
  padding: 2rem;
}

.component__link-list__link a {
  text-decoration: none;
}
.component__link-list__link a:after {
  content: "\f054";
  font-family: var(--f-family-fontawesome);
  font-weight: 300;
  margin-left: 1.6rem;
}
.component__link-list--strokes .component__link-list__link {
  padding: 1.6rem 0;
  border-top: 1px solid var(--c-text);
}
.component__link-list--strokes .component__link-list__link:last-child {
  border-bottom: 1px solid var(--c-text);
}
.component__link-list--strokes .component__link-list__link a {
  position: relative;
  padding-right: 3rem;
  display: block;
  width: 100%;
}
.component__link-list--strokes .component__link-list__link a:after {
  content: "\f054";
  font-family: var(--f-family-fontawesome);
  font-weight: 300;
  margin-left: 1.6rem;
  position: absolute;
  top: 0;
  right: 0;
}

.columns__column--one .component__link-list {
  font-size: 4rem;
  font-family: var(--f-family-serif);
}

.color-light .component__link-list--strokes .component__link-list__link {
  border-top: 1px solid var(--c-primary-white);
}
.color-light .component__link-list--strokes .component__link-list__link:last-child {
  border-bottom: 1px solid var(--c-primary-white);
}

.component__search-box {
  position: relative;
  max-width: 11rem;
  width: 100%;
  transition: 0.4s;
}
.component__search-box::before {
  font-family: var(--f-family-fontawesome);
  content: "\f002";
  position: absolute;
  font-size: 1.6rem;
  top: 1.4rem;
  left: 2rem;
}
.component__search-box:focus-within {
  max-width: 41rem;
}
.component__search-box .component__search-text {
  background: var(--c-primary-green);
  padding: 1.2rem;
  padding-left: 5.6rem;
  border-radius: 0.4rem;
  width: 100%;
  color: var(--c-text);
  transition: 0.2s;
}
.component__search-box .component__search-text:focus {
  outline: none;
}
.component__search-box .component__search-text:focus + .component__search-button {
  visibility: visible;
  top: 1.4rem;
  right: 2rem;
}
.component__search-box .component__search-text::placeholder {
  color: var(--c-text);
  opacity: 0.5;
}
.component__search-box .component__search-text:is(:hover, :focus) {
  background-color: var(--c-extra-lightgreen);
}
.component__search-box .component__search-button {
  font-family: var(--f-family-fontawesome);
  position: absolute;
  visibility: hidden;
}

.component__top-carousel {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  max-height: 200rem;
  max-width: 200rem;
}
.component__top-carousel .top-carousel__images {
  width: 100%;
  overflow: hidden;
  position: relative;
  transition: transform 0.3s ease;
}
.component__top-carousel .top-carousel__image {
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
}
@media (min-width: 976px) {
  .component__top-carousel .top-carousel__image {
    aspect-ratio: 3/4;
  }
}
@media (min-width: 1440px) {
  .component__top-carousel .top-carousel__image {
    aspect-ratio: 1/1;
  }
}
.component__top-carousel .top-carousel__thumbnails {
  display: flex;
  position: absolute;
  flex-direction: row;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
}
@media (min-width: 976px) {
  .component__top-carousel .top-carousel__thumbnails {
    flex-direction: column;
    left: 0;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
.component__top-carousel .top-carousel__thumbnail {
  width: 5rem;
  height: 5rem;
  margin-right: 1.4rem;
  border-radius: 50%;
  cursor: pointer;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border: 0.5rem solid var(--c-primary-white);
  transition: 0.2s;
}
.component__top-carousel .top-carousel__thumbnail:is(:hover, :focus, :active) {
  border: 0.5rem solid var(--c-primary-red);
  outline: none;
}
.component__top-carousel .top-carousel__thumbnail:last-child {
  margin-right: 0;
}
@media (min-width: 976px) {
  .component__top-carousel .top-carousel__thumbnail {
    margin-bottom: 0.6rem;
    margin-right: 0;
  }
  .component__top-carousel .top-carousel__thumbnail:last-child {
    margin-bottom: 0;
  }
}

.component__accordion .accordion__item {
  border-top: 1px solid var(--c-primary-white);
}
.component__accordion .accordion__item:last-child {
  border-bottom: 1px solid var(--c-primary-white);
}
.component__accordion .accordion__item__expanded .accordion__header__arrow {
  transform: rotate(180deg);
}
.component__accordion .accordion__item__expanded .accordion__content {
  display: block;
}
.component__accordion .accordion__header {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  line-height: 2;
  text-align: left;
  width: 100%;
  transition: 0.2s;
  padding: 1.7rem 0;
}
.component__accordion .accordion__header:is(:hover, :focus, :active) {
  position: relative;
  background-color: var(--c-primary-white);
  padding: 1.7rem;
  margin-left: -1.7rem;
  margin-right: -1.7rem;
  width: calc(100% + 3.4rem);
  border-radius: 0.4rem;
  color: var(--c-text);
  outline: none;
}
.component__accordion .accordion__header__type-display {
  opacity: 0.8;
  padding-right: 2rem;
  font-size: 1.6rem;
  width: 14rem;
}
@media (min-width: 768px) {
  .component__accordion .accordion__header__type-display {
    width: 20rem;
  }
}
.component__accordion .accordion__header__arrow {
  transition: 0.2s;
}
.component__accordion .accordion__content {
  padding: 1.6rem 0;
  display: none;
}
@media (min-width: 768px) {
  .component__accordion .accordion__content {
    padding: 2.2rem 20rem 3rem;
  }
}
.component__accordion .accordion__content p, .component__accordion .accordion__content a {
  margin-bottom: 0.8rem;
}
.component__accordion .accordion__content a {
  display: block;
  text-decoration: none;
}

div.input-block {
  position: relative;
}
div.input-block input[type=text], div.input-block input[type=tlf], div.input-block input[type=number], div.input-block input[type=email], div.input-block textarea, div.input-block:has(input[type=radio]), div.input-block:has(input[type=checkbox]) {
  font-weight: 400;
  font-size: 1.8rem;
  color: var(--c-text);
  width: 100%;
  padding: 1.2rem 2.4rem;
  border-radius: 0.4rem;
  border: 1px solid var(--c-primary-white);
  outline: none;
  background-color: var(--c-primary-white);
}
div.input-block input[type=text] + label, div.input-block input[type=tlf] + label, div.input-block input[type=number] + label, div.input-block input[type=email] + label, div.input-block textarea + label, div.input-block:has(input[type=radio]) + label, div.input-block:has(input[type=checkbox]) + label {
  position: absolute;
  padding: 1.5rem 0;
  font-size: 1.4rem;
  top: 0.1rem;
  left: 2.4rem;
  transition: all 0.3s;
  transform-origin: 0% 0%;
  pointer-events: none;
  color: var(--c-extra-darkgreen);
  display: block;
  z-index: 5;
}
div.input-block input[type=text]:not(:placeholder-shown), div.input-block input[type=text]:focus, div.input-block input[type=tlf]:not(:placeholder-shown), div.input-block input[type=tlf]:focus, div.input-block input[type=number]:not(:placeholder-shown), div.input-block input[type=number]:focus, div.input-block input[type=email]:not(:placeholder-shown), div.input-block input[type=email]:focus, div.input-block textarea:not(:placeholder-shown), div.input-block textarea:focus, div.input-block:has(input[type=radio]):not(:placeholder-shown), div.input-block:has(input[type=radio]):focus, div.input-block:has(input[type=checkbox]):not(:placeholder-shown), div.input-block:has(input[type=checkbox]):focus {
  padding-top: 3.5rem;
}
div.input-block input[type=text]:not(:placeholder-shown) + label, div.input-block input[type=text]:focus + label, div.input-block input[type=tlf]:not(:placeholder-shown) + label, div.input-block input[type=tlf]:focus + label, div.input-block input[type=number]:not(:placeholder-shown) + label, div.input-block input[type=number]:focus + label, div.input-block input[type=email]:not(:placeholder-shown) + label, div.input-block input[type=email]:focus + label, div.input-block textarea:not(:placeholder-shown) + label, div.input-block textarea:focus + label, div.input-block:has(input[type=radio]):not(:placeholder-shown) + label, div.input-block:has(input[type=radio]):focus + label, div.input-block:has(input[type=checkbox]):not(:placeholder-shown) + label, div.input-block:has(input[type=checkbox]):focus + label {
  color: var(--c-secondary-blue);
  transform: scale(0.8) translateY(0px);
}
div.input-block input[type=text]:focus, div.input-block input[type=tlf]:focus, div.input-block input[type=number]:focus, div.input-block input[type=email]:focus, div.input-block textarea:focus, div.input-block:has(input[type=radio]):focus, div.input-block:has(input[type=checkbox]):focus {
  border-color: var(--c-primary-red);
  border-radius: 0;
}
div.input-block input[type=text]::placeholder, div.input-block input[type=tlf]::placeholder, div.input-block input[type=number]::placeholder, div.input-block input[type=email]::placeholder, div.input-block textarea::placeholder, div.input-block:has(input[type=radio])::placeholder, div.input-block:has(input[type=checkbox])::placeholder {
  color: transparent;
}
div.input-block:has(input[type=radio]) label, div.input-block:has(input[type=checkbox]) label {
  font-size: 1.4rem;
  color: var(--c-text);
}
div.input-block:has(input[type=radio]):not(:placeholder-shown), div.input-block:has(input[type=checkbox]):not(:placeholder-shown) {
  padding-top: 1.2rem;
}
div.input-block:has(input[type=radio]) input[type=radio],
div.input-block:has(input[type=radio]) input[type=checkbox], div.input-block:has(input[type=checkbox]) input[type=radio],
div.input-block:has(input[type=checkbox]) input[type=checkbox] {
  appearance: none;
  margin: 0;
  width: 1.6rem;
  height: 1.6rem;
  border: 0.1rem solid var(--c-primary-red);
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  display: inline-grid;
  place-content: center;
}
div.input-block:has(input[type=radio]) input[type=radio]::before,
div.input-block:has(input[type=radio]) input[type=checkbox]::before, div.input-block:has(input[type=checkbox]) input[type=radio]::before,
div.input-block:has(input[type=checkbox]) input[type=checkbox]::before {
  transform: scale(0);
  transition: 120ms transform ease-in-out;
}
div.input-block:has(input[type=radio]) input[type=radio]:checked::before,
div.input-block:has(input[type=radio]) input[type=checkbox]:checked::before, div.input-block:has(input[type=checkbox]) input[type=radio]:checked::before,
div.input-block:has(input[type=checkbox]) input[type=checkbox]:checked::before {
  transform: scale(1);
}
div.input-block:has(input[type=radio]) input[type=radio], div.input-block:has(input[type=checkbox]) input[type=radio] {
  border-radius: 50%;
}
div.input-block:has(input[type=radio]) input[type=radio]::before, div.input-block:has(input[type=checkbox]) input[type=radio]::before {
  content: "";
  border-radius: 50%;
  background-color: var(--c-primary-red);
  width: 0.8rem;
  height: 0.8rem;
}
div.input-block:has(input[type=radio]) input[type=checkbox], div.input-block:has(input[type=checkbox]) input[type=checkbox] {
  border-radius: 0.2rem;
}
div.input-block:has(input[type=radio]) input[type=checkbox]::before, div.input-block:has(input[type=checkbox]) input[type=checkbox]::before {
  font-family: var(--f-family-fontawesome);
  content: "\f00c";
  border-radius: 0.2rem;
  font-size: 0.8rem;
  width: 0.9rem;
  height: 1.4rem;
  font-size: 1rem;
  color: var(--c-primary-red);
}
div.input-block + .input-block {
  margin-top: 1.6rem;
}
div.input-block textarea {
  min-height: 10rem;
}

header.header--main-header {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  overflow: hidden;
}
header.header--main-header .header-image {
  margin-top: 4rem;
}
header.header--main-header .header-image__image {
  width: 11rem;
  background-image: url("images/logo_main.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 5.6rem;
}
header.header--main-header .header-image__link:is(:hover, :focus, :active) {
  opacity: 0.7;
}
header.header--main-header nav.sub-menu {
  background-color: var(--c-primary-white);
  padding: 0.8rem 0 0.8rem 2rem;
  position: relative;
  overflow-y: clip;
}
header.header--main-header nav.sub-menu::before {
  content: "";
  width: 0;
  height: 0;
  border-top: 4.2rem solid var(--c-primary-white);
  border-left: 2rem solid transparent;
  position: absolute;
  left: -1.9rem;
  top: 0;
}
header.header--main-header nav.sub-menu::after {
  content: "";
  background-color: var(--c-primary-white);
  height: 4rem;
  width: 50vw;
  right: -50vw;
  top: 0;
  position: absolute;
}
header.header--main-header nav.sub-menu .menu-item a {
  text-decoration: none;
}
header.header--main-header nav.sub-menu .menu-item + .menu-item {
  margin-left: 1.6rem;
}
header.header--main-header nav.sub-menu .menu-item--change-supplier {
  padding-right: 1.6rem;
  border-right: 1px solid var(--c-text);
}
header.header--main-header nav.sub-menu .menu-item--search {
  font-size: 1.6rem;
}
header.header--main-header nav.main-menu {
  margin-top: 2.4rem;
}
header.header--main-header nav.main-menu .menu-item a {
  text-decoration: none;
  color: var(--c-text-contrast);
}
header.header--main-header nav.main-menu .menu-item a:is(:hover, :focus, :active), header.header--main-header nav.main-menu .menu-item a.active {
  text-decoration: underline;
  text-underline-offset: 0.7rem;
  text-decoration-color: var(--c-text-contrast);
  text-decoration-thickness: 0.3rem;
  color: var(--c-text-contrast);
}
header.header--main-header nav.main-menu .menu-item + .menu-item {
  margin-left: 2.4rem;
}
header.header--main-header.header--white nav.sub-menu {
  background-color: var(--c-primary-green);
}
header.header--main-header.header--white nav.sub-menu::before {
  border-top: 4rem solid var(--c-primary-green);
}
header.header--main-header.header--white nav.sub-menu::after {
  content: "";
  background-color: var(--c-primary-green);
}
header.header--main-header.header--white nav.main-menu .menu-item a {
  color: var(--c-text);
}
header.header--main-header.header--white nav.main-menu .menu-item a:is(:hover, :focus, :active), header.header--main-header.header--white nav.main-menu .menu-item a.active {
  text-decoration-color: var(--c-primary-green);
  color: var(--c-text);
}
header.header--main-header.header--dark .header-image__image {
  background-image: url("images/logo_white.svg");
}
header.header--main-header.header--dark .header__mobile-menu {
  color: var(--c-text-contrast);
}
header.header--main-header.header--colored .header-image__image {
  background-image: url("images/logo_dark.svg");
}
header.header--main-header.header--colored nav.main-menu .menu-item a {
  color: var(--c-text);
}
header.header--main-header.header--colored nav.main-menu .menu-item a:is(:hover, :focus, :active), header.header--main-header.header--colored nav.main-menu .menu-item a.active {
  color: var(--c-text);
}

footer.footer {
  background-color: var(--c-primary-green);
  padding: 8rem 0 16rem;
  position: relative;
}
footer.footer .footer-item {
  margin-bottom: 4rem;
}
@media (min-width: 768px) {
  footer.footer .footer-item {
    margin-bottom: 0;
  }
}
footer.footer .footer-item__emblem {
  width: 120px;
  height: 120px;
  margin-right: 4rem;
}
footer.footer .footer-item__textfield {
  margin-bottom: 4rem;
}
footer.footer .footer-item__textfield__title {
  font-size: 1.8rem;
  font-weight: 700;
}
footer.footer .footer-item__socials__social-link i {
  font-size: 3.2rem;
}
footer.footer .footer-item__socials__social-link + .footer-item__socials__social-link {
  margin-left: 1.6rem;
}
footer.footer .component__link-list li {
  margin-bottom: 1.6rem;
}
footer.footer .back-to-top-btn {
  width: 5rem;
  height: 5rem;
  position: absolute;
  top: -10.5rem;
  right: 4rem;
}
footer.footer .back-to-top-btn:is(:hover, :focus, :active) {
  border-color: var(--c-primary);
  background-color: var(--c-primary);
  color: var(--c-text-contrast);
}

.breadcrumbs__breadcrumb-link {
  text-decoration: none;
  font-size: 1.4rem;
}
.breadcrumbs__breadcrumb-link::after {
  content: "/";
  margin-left: 0.5rem;
}
.breadcrumbs__breadcrumb-link:last-child:after {
  content: "";
}
.breadcrumbs__breadcrumb-link--active {
  text-decoration: underline;
  text-decoration-color: var(--c-primary-red);
  text-underline-offset: 0.4rem;
}

.mobile-menu__menu {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: var(--c-primary-green);
  overflow: auto;
  padding-top: 16.8rem;
}
.mobile-menu__menu .component__link-list {
  font-family: var(--f-family-serif);
  font-size: 2.8rem;
  padding: 0 4rem 4rem;
  background-color: var(--c-primary-green);
  position: relative;
  z-index: 20;
}
.mobile-menu__submenu {
  background-color: var(--c-primary-white);
  color: var(--c-secondary-blue);
  padding: 4rem;
}
.mobile-menu__submenu a {
  text-decoration: none;
}
.mobile-menu__submenu a:is(:hover, :focus, :active) {
  text-decoration: underline;
}
.mobile-menu__submenu .menu-item {
  margin-bottom: 1.6rem;
}
.mobile-menu__submenu .menu-item:last-child {
  margin-bottom: 0;
}
.mobile-menu__submenu .menu-item--stroke {
  padding-bottom: 1.6rem;
  border-bottom: 1px solid var(--c-secondary-blue);
}
.mobile-menu__menu-button {
  position: relative;
  z-index: 15;
  font-size: 3rem;
  width: 4rem;
  height: 4rem;
  line-height: 4rem;
  margin-top: 4rem;
  border-radius: 50%;
  transition: 0.2s;
}
.mobile-menu__menu-button:is(:hover, :focus, :active) {
  background-color: var(--c-primary-red);
  outline: 5px solid var(--c-primary-red);
  color: var(--c-text-contrast);
}

.page.page--search {
  padding-top: 20rem;
  padding-bottom: 2.4rem;
}
.page.page--search .search-page__form-wrapper .component__search-box {
  max-width: 100%;
}
.page.page--search .search-page__form-wrapper .component__search-box:has(.component__search-text:focus) {
  max-width: 100%;
}
.page.page--search .search-page__form-wrapper .component__search-box .component__search-text {
  background-color: var(--c-primary-white);
}
.page.page--search .search-page__form-wrapper .component__search-box .component__search-button {
  visibility: visible;
  top: 1.4rem;
  right: 2rem;
}
.page.page--search .search-page__results {
  max-width: 106rem;
  margin: 6rem auto 0;
}
.page.page--search .search-page__results__info {
  margin-bottom: 2.4rem;
  font-style: italic;
}
.page.page--search .search-page__results__list-item {
  border-top: 1px solid var(--c-primary-black);
  padding: 1.7rem 0;
  display: block;
  text-decoration: none;
  color: var(--c-text);
  transition: 0.2s background-color, margin, padding;
  position: relative;
}
.page.page--search .search-page__results__list-item:last-child {
  border-bottom: 1px solid var(--c-primary-black);
}
.page.page--search .search-page__results__list-item:is(:hover, :focus, :active) {
  position: relative;
  background-color: var(--c-primary-white);
  padding: 1.7rem;
  margin-left: -1.7rem;
  margin-right: -1.7rem;
  width: calc(100% + 3.4rem);
  border-top: 1px solid var(--c-primary-white);
  border-bottom: 1px solid var(--c-primary-white);
  border-radius: 0.4rem;
  color: var(--c-text);
}
.page.page--search .search-page__results__list-item:is(:hover, :focus, :active) + .search-page__results__list-item {
  border-top: 0;
}
.page.page--search .search-page__results__list-item .list-item__page-type {
  max-width: 30rem;
  width: 100%;
  margin-bottom: 0.8rem;
}
@media (min-width: 768px) {
  .page.page--search .search-page__results__list-item .list-item__page-type {
    margin-bottom: 0;
  }
}
.page.page--search .search-page__results__list-item .list-item__page-title {
  width: 100%;
}
.page.page--search .search-page__results__list-item .list-item__arrow {
  position: absolute;
  right: 0;
}
@media (min-width: 768px) {
  .page.page--search .search-page__results__list-item .list-item__arrow {
    position: static;
  }
}

.page.page--search-supplier {
  padding-top: 20rem;
}
.page.page--search-supplier .search-supplier__top {
  text-align: center;
}
.page.page--search-supplier .search-supplier__top .component__search-box {
  margin: 4rem auto 8rem;
  max-width: 29rem;
}
.page.page--search-supplier .search-supplier__top .component__search-box:is(:hover, :focus, :active), .page.page--search-supplier .search-supplier__top .component__search-box:has(.component__search-text:focus) {
  max-width: 50rem;
}
.page.page--search-supplier .search-supplier__content {
  padding-top: 8rem;
  padding-bottom: 12rem;
}
.page.page--search-supplier .search-supplier__search {
  width: 100%;
}
.page.page--search-supplier .search-supplier__search__text {
  margin-bottom: 3.2rem;
}
@media (min-width: 768px) {
  .page.page--search-supplier .search-supplier__search__text {
    margin-bottom: 6.9rem;
  }
}
.page.page--search-supplier .search-supplier__search__title {
  margin-bottom: 3.2rem;
}
@media (min-width: 768px) {
  .page.page--search-supplier .search-supplier__search__title {
    margin-bottom: 4.9rem;
  }
}
.page.page--search-supplier .search-supplier__search__filters {
  margin-bottom: 4rem;
}
.page.page--search-supplier .search-supplier__search__filter-bullet {
  display: inline;
}
@media (min-width: 768px) {
  .page.page--search-supplier .search-supplier__search__filter {
    max-width: 30rem;
    margin-right: 1.6rem;
  }
}
@media (min-width: 768px) {
  .page.page--search-supplier .search-supplier__filters {
    margin-right: 13rem;
  }
}
@media (min-width: 768px) {
  .page.page--search-supplier .search-supplier__filters__nav {
    display: block;
  }
}
.page.page--search-supplier .search-supplier__filters__header {
  margin-bottom: 2.4rem;
}
@media (min-width: 768px) {
  .page.page--search-supplier .search-supplier__filters__header {
    display: block;
  }
}
.page.page--search-supplier .search-supplier__filters__filter {
  padding: 10px;
  background-color: var(--c-opacity-gray);
  margin-bottom: 0.8rem;
  text-align: center;
  border-radius: 0.4rem;
  border: 0;
  color: var(--c-text-contrast);
  width: 100%;
  transition: 0.2s;
}
.page.page--search-supplier .search-supplier__filters__filter:is(:hover, :focus, :active, .selected) {
  background-color: var(--c-primary-white);
  color: var(--c-text);
}
.page.page--search-supplier .search-supplier__filters__select {
  color: var(--c-text);
  padding: 0.8rem 1.6rem;
  border-radius: 4px;
  margin-bottom: 4.8rem;
}

.leaflet-container {
  margin-bottom: 5rem;
  z-index: 0;
}
.leaflet-container .leaflet-popup h2 {
  font-size: 2rem;
  color: var(--c-secondary-blue);
  margin-bottom: 1.4rem;
}
.leaflet-container .leaflet-popup p {
  color: var(--c-secondary-blue);
  font-size: 1.4rem;
  margin-bottom: 0;
  margin-top: 0.8rem;
}

.page--home-page .home-page__top {
  position: relative;
  padding-top: 14rem;
  align-items: center;
}
@media (min-width: 768px) {
  .page--home-page .home-page__top {
    min-height: 90rem;
    padding-top: 22rem;
    padding-bottom: 22rem;
  }
}
.page--home-page .home-page__top .home-page__top__images {
  position: relative;
}
.page--home-page .home-page__top .home-page__top__images:has(.home-page__top__images__video-clip-path video) {
  width: 100%;
  height: 50vw;
}
@media (min-width: 768px) {
  .page--home-page .home-page__top .home-page__top__images:has(.home-page__top__images__video-clip-path video) {
    width: auto;
    height: auto;
  }
}
@media (min-width: 768px) {
  .page--home-page .home-page__top .home-page__top__images {
    position: static;
  }
}
.page--home-page .home-page__top .home-page__top__images img, .page--home-page .home-page__top .home-page__top__images__video-clip-path {
  width: 100%;
  object-fit: cover;
  top: 0;
  bottom: 0;
  height: 100%;
}
.page--home-page .home-page__top .home-page__top__images__video-clip-path video {
  height: 100%;
  width: 100%;
  object-fit: cover;
  top: 0;
  bottom: 0;
  position: absolute;
}
@media (min-width: 768px) {
  .page--home-page .home-page__top .home-page__top__images__video-clip-path video {
    margin-left: 36%;
  }
}
@media (min-width: 768px) {
  .page--home-page .home-page__top .home-page__top__images__video-clip-path::after {
    content: "";
    height: 20rem;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(rgba(0, 0, 0, 0.6) 0%, transparent 80%);
  }
}
.page--home-page .home-page__top .home-page__top__images__video__play-button {
  position: absolute;
  bottom: 3rem;
  right: 3rem;
  background-color: var(--c-opacity-black);
  font-size: 2rem;
  color: var(--c-text-contrast);
  border-radius: 50%;
  height: 4rem;
  width: 4rem;
}
.page--home-page .home-page__top .home-page__top__images__video__play-button:is(:hover, :focus, :active) {
  background-color: var(--c-opacity-red);
}
.page--home-page .home-page__top .home-page__top__images .image-one {
  clip-path: polygon(10% 0, 100% 0, 100% 100%, 32% 100%);
}
@media (min-width: 768px) {
  .page--home-page .home-page__top .home-page__top__images .image-one {
    clip-path: polygon(50% 0, 100% 0, 100% 100%, 75% 100%);
    position: absolute;
  }
}
@media (min-width: 1200px) {
  .page--home-page .home-page__top .home-page__top__images .image-one {
    clip-path: polygon(40% 0, 100% 0, 100% 100%, 65% 100%);
  }
}
.page--home-page .home-page__top .home-page__top__images .image-two {
  clip-path: polygon(10% 60%, 22% 60%, 31% 100%, 0% 100%);
  position: absolute;
}
@media (min-width: 768px) {
  .page--home-page .home-page__top .home-page__top__images .image-two {
    clip-path: polygon(57% 60%, 64% 60%, 74% 100%, 46% 100%);
  }
}
@media (min-width: 1200px) {
  .page--home-page .home-page__top .home-page__top__images .image-two {
    clip-path: polygon(48% 60%, 54% 60%, 64% 100%, 38% 100%);
  }
}
.page--home-page .home-page__top .home-page__top__images .home-page__top__images__video--one .home-page__top__images__video-clip-path {
  position: absolute;
}
@media (min-width: 768px) {
  .page--home-page .home-page__top .home-page__top__images .home-page__top__images__video--one .home-page__top__images__video-clip-path {
    clip-path: polygon(40% 0, 100% 0, 100% 100%, 60% 100%);
    max-width: 400rem;
    right: 0;
  }
}
@media (min-width: 1200px) {
  .page--home-page .home-page__top .home-page__top__images .home-page__top__images__video--one .home-page__top__images__video-clip-path {
    clip-path: polygon(36% 0, 100% 0, 100% 100%, 50% 100%);
  }
}
.page--home-page .home-page__top .home-page__top__text-field {
  padding-top: 4rem;
  margin-bottom: 4rem;
}
@media (min-width: 768px) {
  .page--home-page .home-page__top .home-page__top__text-field {
    padding-top: 0;
    max-width: 46%;
  }
}
@media (min-width: 1200px) {
  .page--home-page .home-page__top .home-page__top__text-field {
    max-width: 32%;
  }
}
.page--home-page .home-page__top .home-page__top__title,
.page--home-page .home-page__top .home-page__top__intro {
  margin-bottom: 2.4rem;
}
@media (min-width: 768px) {
  .page--home-page .home-page__top .home-page__top__title,
  .page--home-page .home-page__top .home-page__top__intro {
    margin-bottom: 4rem;
  }
}

.page--page .page__top {
  position: relative;
  padding-top: 14rem;
}
@media (min-width: 768px) {
  .page--page .page__top {
    padding-top: 22rem;
  }
}
.page--page .page__top--back-link {
  padding-top: 10rem;
}
@media (min-width: 768px) {
  .page--page .page__top--back-link {
    padding-top: 18rem;
  }
}
.page--page .page__top__text-field {
  padding-bottom: 8rem;
}
.page--page .page__top--overlay .page__top__text-field {
  padding-bottom: 20rem;
}
.page--page .page__top__title {
  margin-bottom: 1.6rem;
}
@media (min-width: 768px) {
  .page--page .page__top__title {
    margin-bottom: 0;
  }
}
.page--page .page__top__back-link {
  font-size: 1.4rem;
  margin-bottom: 1.6rem;
  text-decoration: none;
  display: block;
}
.page--page .page__top__image img, .page--page .page__top__image video {
  width: 100%;
}
.page--page .page__top__image video {
  max-height: 52rem;
  object-fit: cover;
}

.page--article .article__top {
  padding-top: 21.8rem;
}
.page--article .article__top__back-link {
  text-decoration: none;
  font-size: 1.4rem;
  font-weight: 500;
  margin-bottom: 2rem;
  display: inline-block;
}
.page--article .article__top__back-link:is(:focus, :hover, :active) {
  text-decoration: underline;
}
.page--article .article__top__text-field {
  text-align: center;
}
.page--article .article__top__sub-title {
  text-transform: uppercase;
  margin-bottom: 1.2rem;
}
.page--article .article__top__title {
  margin-bottom: 4rem;
}
.page--article .article__top__intro {
  max-width: 85rem;
  margin: auto;
}
.page--article .article__image img, .page--article .article__image .article__image__wrapper-inner {
  position: relative;
  margin: -200px auto 0;
  width: fit-content;
}
.page--article .article__body {
  margin-top: 6.4rem;
  margin-bottom: 6.4rem;
}
.page--article .article__body p + p {
  margin-top: 2em;
}
.page--article .article__body .article__block {
  margin-bottom: 5rem;
}
.page--article .article__body .richtext-image.full-width,
.page--article .article__body .blockitem__carouselblock__wrapper {
  margin-top: 4rem;
  margin-bottom: 4rem;
}
@media (min-width: 768px) {
  .page--article .article__body .richtext-image.full-width,
  .page--article .article__body .blockitem__carouselblock__wrapper {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }
}
@media (min-width: 976px) {
  .page--article .article__body .richtext-image.full-width,
  .page--article .article__body .blockitem__carouselblock__wrapper {
    left: 50%;
    margin-left: -50rem;
    margin-right: -50rem;
    max-width: 100rem;
    position: relative;
    right: 50%;
    width: 100rem;
  }
}
@media (min-width: 1200px) {
  .page--article .article__body .richtext-image.full-width,
  .page--article .article__body .blockitem__carouselblock__wrapper {
    margin-left: -55rem;
    margin-right: -55rem;
    max-width: 110rem;
    width: 110rem;
  }
}
@media (min-width: 1440px) {
  .page--article .article__body .richtext-image.full-width,
  .page--article .article__body .blockitem__carouselblock__wrapper {
    margin-left: -64rem;
    margin-right: -64rem;
    max-width: 128rem;
    width: 128rem;
  }
}
@media (min-width: 1200px) {
  .page--article .article__body .blockitem__quoteblock {
    left: 50%;
    margin-left: -53.1rem;
    margin-right: -53.1rem;
    max-width: 106.2rem;
    position: relative;
    right: 50%;
    width: 106.2rem;
  }
}

.page--product-page .product-page__top__title {
  margin-bottom: 4rem;
  margin-top: 1rem;
}
@media (min-width: 768px) {
  .page--product-page .product-page__top__title {
    font-size: 3.8rem;
  }
}
@media (min-width: 1200px) {
  .page--product-page .product-page__top__title {
    font-size: 4.8rem;
  }
}
.page--product-page .product-page__top__text-field-wrapper {
  padding: 16rem 2.4rem 10rem 2.4rem;
}
@media (min-width: 768px) {
  .page--product-page .product-page__top__text-field-wrapper {
    padding: 16rem 6rem 8rem 4rem;
  }
}
@media (min-width: 1200px) {
  .page--product-page .product-page__top__text-field-wrapper {
    padding: 24rem 8rem 8rem 4rem;
    display: flex;
    align-items: end;
    flex-direction: column;
  }
}
.page--product-page .product-page__top__text-field-wrapper p + p {
  margin-top: 1.2em;
}
.page--product-page .product-page__top__text-field {
  max-width: 45rem;
}
.page--product-page .product-page__top__colors {
  margin-top: 6.4rem;
}
.page--product-page .product-page__top__color {
  margin-right: 3rem;
  text-align: center;
}
.page--product-page .product-page__top__color__image {
  border-radius: 50%;
  margin-bottom: 0.8rem;
  width: 8rem;
  height: 8rem;
  margin-left: auto;
  margin-right: auto;
}

.page--contact-page .contact-page__top-field {
  padding-top: 11rem;
  padding-bottom: 8rem;
}
@media (min-width: 768px) {
  .page--contact-page .contact-page__top-field {
    padding-top: 22.4rem;
  }
}
.page--contact-page .contact-page__text-field__title {
  margin-bottom: 4rem;
}
@media (min-width: 768px) {
  .page--contact-page .contact-page__text-field__title {
    margin-bottom: 6.4rem;
  }
}
.page--contact-page .contact-page__text-field__intro {
  margin-bottom: 4rem;
}
@media (min-width: 768px) {
  .page--contact-page .contact-page__text-field__intro {
    margin-bottom: 3rem;
  }
}
.page--contact-page .contact-page__text-field__body {
  margin-bottom: 4rem;
}
@media (min-width: 768px) {
  .page--contact-page .contact-page__text-field__body {
    margin-bottom: 0rem;
  }
}

.block__columns--overlay .columns__column {
  position: relative;
  margin-top: -8rem;
}
@media (min-width: 768px) {
  .block__columns--overlay .columns__column {
    margin-top: -16rem;
  }
}
.block__columns .columns__column--top-bottom-space {
  padding-top: 8rem;
  padding-bottom: 8rem;
}
.block__columns .columns__block {
  display: flex;
}
.block__columns .columns__block--colored {
  margin-left: -4rem;
  width: calc(100% + 8rem);
  padding: 4rem;
}
@media (min-width: 768px) {
  .block__columns .columns__block--colored {
    margin-left: -2rem;
    margin-right: -4rem;
    width: calc(100% + 6rem);
    padding: 6rem;
  }
}
.block__columns .columns__link {
  text-decoration: none;
}
.block__columns .columns__link i {
  margin-left: 1rem;
}

.block__parallax {
  padding: 8rem 3rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media (min-width: 768px) {
  .block__parallax {
    padding: 18rem;
  }
}
.block__parallax--parallax {
  background-attachment: fixed;
}
.block__parallax .parallax__textbox {
  padding: 4rem 4rem 6rem;
  text-align: center;
}
.block__parallax .parallax__icon {
  margin: 0 auto 3rem;
}
.block__parallax .parallax__title {
  margin-bottom: 3rem;
}
.block__parallax .parallax__text {
  margin-bottom: 4rem;
}
.block__parallax .parallax__text li {
  list-style-position: inside;
}

.block__contactform {
  background-color: var(--c-secondary-gray);
  padding: 4.8rem 0;
}
@media (min-width: 768px) {
  .block__contactform {
    padding: 8rem 0;
  }
}
.block__contactform .contactform__title {
  margin-bottom: 3.2rem;
}
@media (min-width: 768px) {
  .block__contactform .contactform__title {
    margin-bottom: 4rem;
  }
}
.block__contactform .contactform__label {
  margin-bottom: 0.8rem;
  display: block;
}
.block__contactform .contactform__supplier-link {
  text-decoration: none;
  margin-bottom: 4.8rem;
  display: block;
}
.block__contactform .input-block--search {
  margin-bottom: 2.4rem;
}
@media (min-width: 768px) {
  .block__contactform .input-block--search {
    margin-bottom: 3.2rem;
  }
}
.block__contactform .input-block--search::before {
  font-family: var(--f-family-fontawesome);
  content: "\f002";
  position: absolute;
  font-size: 1.6rem;
  top: 1.4rem;
  left: 2rem;
}
.block__contactform .input-block--search input[type=text] {
  padding-left: 5rem;
}
.block__contactform .input-block--search input[type=text] + label {
  left: 5rem;
}

.tabmodule {
  padding-top: 2.4rem;
  padding-bottom: 2.4rem;
}
@media (min-width: 768px) {
  .tabmodule {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
}
.tabmodule .tabmodule__tablist {
  display: flex;
  flex-direction: column;
}
.tabmodule .tabmodule__tablist__tab {
  background-color: var(--c-extra-white);
  cursor: pointer;
  opacity: 0.5;
  margin-bottom: 0.8rem;
  color: var(--c-text);
}
.tabmodule .tabmodule__tablist__tab.active {
  opacity: 1;
}
.tabmodule .tabmodule__tablist__tab:is(:hover, :focus, :active) {
  opacity: 1;
  outline: none;
}
@media (min-width: 768px) {
  .tabmodule .tabmodule__tabpanel-container {
    margin-left: 7rem;
  }
}
@media (min-width: 976px) {
  .tabmodule .tabmodule__tabpanel-container {
    margin-left: 13rem;
  }
}
.tabmodule .tabmodule__tabpanel {
  display: none;
}
.tabmodule .tabmodule__tabpanel.active {
  display: block;
}
.tabmodule .tabmodule__tabpanel__title, .tabmodule .tabmodule__tabpanel__intro {
  margin-bottom: 2.4rem;
}
.tabmodule .tabmodule__tabpanel__title {
  margin-top: 2.4rem;
}
@media (min-width: 768px) {
  .tabmodule .tabmodule__tabpanel__title {
    margin-top: 0;
  }
}

.block__sub-items {
  padding-bottom: 6rem;
  padding-top: 6rem;
}
@media (min-width: 768px) {
  .block__sub-items {
    padding-bottom: 10rem;
    padding-top: 10rem;
  }
}
.block__sub-items.block__sub-items--overlay .block__sub-items__wrapper {
  position: relative;
  margin-top: -20rem;
}

.blockitem__linkblock,
.blockitem__textblock,
.blockitem__imageblock {
  margin-bottom: 5rem;
  margin-top: 5rem;
  width: 100%;
}
.blockitem__linkblock img, .blockitem__linkblock video,
.blockitem__textblock img,
.blockitem__textblock video,
.blockitem__imageblock img,
.blockitem__imageblock video {
  width: 100%;
}

.imageblock__video {
  position: relative;
}
.imageblock__video__overlay {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.imageblock__video__play-button {
  background-color: var(--c-opacity-black);
  font-size: 3rem;
  color: var(--c-text-contrast);
  border-radius: 50%;
  height: 8rem;
  width: 8rem;
}
.imageblock__video__play-button:is(:hover, :focus, :active) {
  background-color: var(--c-opacity-red);
}

.blockitem__textblock .textblock__link {
  display: block;
  text-decoration: none;
}
.blockitem__textblock .textblock__link i {
  margin-left: 1rem;
}
.blockitem__textblock .textblock__textarea {
  max-width: 60rem;
}
.blockitem__textblock .textblock__title + .textblock__link {
  margin-top: 1rem;
}
.blockitem__textblock .textblock__link + .textblock__textarea {
  margin-top: 2.4rem;
}
@media (min-width: 768px) {
  .blockitem__textblock .textblock__link + .textblock__textarea {
    margin-top: 4rem;
  }
}
.blockitem__textblock .textblock__title + .textblock__textarea {
  margin-top: 2.4rem;
}
.blockitem__textblock .textblock__textarea:has(+ .textblock__button) {
  margin-bottom: 2.4rem;
}
@media (min-width: 768px) {
  .blockitem__textblock .textblock__textarea:has(+ .textblock__button) {
    margin-bottom: 4rem;
  }
}

.blockitem__imageblock .imageblock__image-link img {
  transition: 0.2s;
}
.blockitem__imageblock .imageblock__image-link:is(:hover, :focus, :active) img {
  opacity: 0.7;
}
.blockitem__imageblock .imageblock__link {
  display: block;
  text-decoration: none;
  margin-top: 2.4rem;
}
.blockitem__imageblock .imageblock__link i {
  margin-left: 1rem;
}
.blockitem__imageblock--graphic-decoration {
  padding-left: 5rem;
  padding-bottom: 3rem;
  background-image: url("images/image_background_graphic.svg");
  background-position: bottom left;
  background-size: 90%;
  background-repeat: no-repeat;
}
@media (min-width: 768px) {
  .blockitem__imageblock--graphic-decoration {
    padding-left: 7rem;
  }
}

.background-white .blockitem__imageblock--graphic-decoration, background-extra_white .blockitem__imageblock--graphic-decoration {
  background-image: url("images/image_background_graphic_beige.svg");
}
.background-white .background-red .blockitem__imageblock--graphic-decoration, .background-white .background-blue .blockitem__imageblock--graphic-decoration, .background-white .background-green .blockitem__imageblock--graphic-decoration, .background-white .background-beige .blockitem__imageblock--graphic-decoration, .background-white .background-light_green .blockitem__imageblock--graphic-decoration, .background-white .background-gray .blockitem__imageblock--graphic-decoration, .background-white .background-orange .blockitem__imageblock--graphic-decoration, background-extra_white .background-red .blockitem__imageblock--graphic-decoration, background-extra_white .background-blue .blockitem__imageblock--graphic-decoration, background-extra_white .background-green .blockitem__imageblock--graphic-decoration, background-extra_white .background-beige .blockitem__imageblock--graphic-decoration, background-extra_white .background-light_green .blockitem__imageblock--graphic-decoration, background-extra_white .background-gray .blockitem__imageblock--graphic-decoration, background-extra_white .background-orange .blockitem__imageblock--graphic-decoration {
  background-image: url("images/image_background_graphic.svg");
}

.columns__column--two .columns__block:nth-child(2) .blockitem__imageblock.blockitem__imageblock--graphic-decoration,
.columns__column--small-wide .columns__column--wide-small .columns__block:nth-child(2) .blockitem__imageblock.blockitem__imageblock--graphic-decoration {
  padding-left: 0;
  padding-right: 5rem;
  background-position: bottom right;
}
@media (min-width: 768px) {
  .columns__column--two .columns__block:nth-child(2) .blockitem__imageblock.blockitem__imageblock--graphic-decoration,
  .columns__column--small-wide .columns__column--wide-small .columns__block:nth-child(2) .blockitem__imageblock.blockitem__imageblock--graphic-decoration {
    padding-right: 7rem;
  }
}
.columns__column--two .blockitem__linkblock,
.columns__column--two .blockitem__textblock,
.columns__column--small-wide .columns__column--wide-small .blockitem__linkblock,
.columns__column--small-wide .columns__column--wide-small .blockitem__textblock {
  margin-top: 8rem;
  margin-bottom: 8rem;
}

.blockitem__linkblock {
  width: 100%;
}

.blockitem__carouselblock {
  position: relative;
  overflow: hidden;
}
.blockitem__carouselblock__wrapper {
  width: 100%;
}
.blockitem__carouselblock .carousel__images {
  display: flex;
  transition: 0.2s;
}
.blockitem__carouselblock .carousel__images picture {
  width: 100%;
  height: auto;
  flex: 0 0 100%;
}
.blockitem__carouselblock .carousel__images picture img {
  width: 100%;
}
.blockitem__carouselblock .carousel__button {
  position: absolute;
  cursor: pointer;
  z-index: 10;
  bottom: 2.4rem;
  width: 5rem;
  height: 5rem;
  background-color: var(--c-extra-pink);
}
.blockitem__carouselblock .carousel__button:is(:hover, :focus, :active) {
  background-color: var(--c-primary);
}
.blockitem__carouselblock .carousel__button.prev {
  right: 9rem;
}
.blockitem__carouselblock .carousel__button.next {
  right: 2.4rem;
}

.columns__column--two .blockitem__carouselblock__wrapper,
.columns__column--small-wide .blockitem__carouselblock__wrapper,
.columns__column--wide-small .blockitem__carouselblock__wrapper {
  margin-left: -4rem;
  margin-right: -4rem;
  width: calc(100% + 8rem);
}
@media (min-width: 768px) {
  .columns__column--two .blockitem__carouselblock__wrapper,
  .columns__column--small-wide .blockitem__carouselblock__wrapper,
  .columns__column--wide-small .blockitem__carouselblock__wrapper {
    margin-right: -2rem;
    width: calc(100% + 6rem);
  }
}
@media (min-width: 768px) {
  .columns__column--two .columns__block:nth-child(even) .blockitem__carouselblock__wrapper,
  .columns__column--small-wide .columns__block:nth-child(even) .blockitem__carouselblock__wrapper,
  .columns__column--wide-small .columns__block:nth-child(even) .blockitem__carouselblock__wrapper {
    margin-left: -2rem;
    margin-right: -4rem;
  }
}

.columns__column--one .blockitem__carouselblock__wrapper {
  max-width: 90rem;
  margin: auto;
}

.blockitem__sub-item {
  position: relative;
  transition: 0.3s;
}
.blockitem__sub-item:is(:hover, :focus-within) {
  background-color: var(--c-extra-white);
}
@media (min-width: 768px) {
  .blockitem__sub-item .sub-item__text-field {
    padding: 2rem;
  }
}
.blockitem__sub-item .sub-item__title-link {
  text-decoration: none;
}
.blockitem__sub-item .sub-item__title-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
}
.blockitem__sub-item .sub-item__title {
  margin-bottom: 1.5rem;
  font-size: 2rem;
}
@media (min-width: 768px) {
  .blockitem__sub-item .sub-item__title {
    font-size: 3.2rem;
  }
}
.blockitem__sub-item .sub-item__link {
  margin-top: 2.5rem;
  display: block;
  text-decoration: none;
}
.blockitem__sub-item .sub-item__link i {
  margin-left: 1rem;
}
.blockitem__sub-item .sub-item__link:is(:hover, :focus, :active) {
  text-decoration: underline;
}
.blockitem__sub-item .sub-item__image picture, .blockitem__sub-item .sub-item__image img {
  width: 100%;
}

.blockitem__quoteblock {
  background-color: var(--c-secondary-blue);
  padding: 4rem;
  color: var(--c-text-contrast);
  text-align: center;
}
@media (min-width: 768px) {
  .blockitem__quoteblock {
    padding: 4rem 8rem 8rem;
  }
}
.blockitem__quoteblock .quoteblock__icon {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3rem;
}
.blockitem__quoteblock .quoteblock__quote {
  color: var(--c-text-contrast);
  margin-bottom: 0.8rem;
}
.blockitem__quoteblock .quoteblock__author {
  font-size: 1.6rem;
}
.blockitem__quoteblock .quoteblock__author:before {
  content: "- ";
}

.blockitem__product-variation {
  background-color: var(--c-extra-white);
  padding: 4.2rem;
  width: 100%;
}
.blockitem__product-variation .product-variation__class {
  color: var(--c-secondary-blue);
  margin-bottom: 0.4rem;
}
.blockitem__product-variation .product-variation__title {
  margin-bottom: 2.4rem;
}
.blockitem__product-variation .product-variation__information-bullet {
  border-bottom: 1px solid var(--c-secondary-gray);
  padding: 0.5rem 0;
}
.blockitem__product-variation .product-variation__information-bullet:last-child {
  border-bottom: 0;
}

.blockitem__table {
  overflow-x: auto;
  display: block;
  width: 100%;
  max-width: 100%;
}